<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.planExpiring')"
    id="planExpiring"
  >
    <div id="outer-title">
      {{ $t("i18n.planExpiring") }}
    </div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.maintenancePlan')"
      v-model="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="formColumns"
        @onSubmit="onSubmit"
        @resetForm="handleDialog('dialogFormVisible', false)"
      ></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "RemindmaintenancePlanBoard",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      dialogFormVisible: false,
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      maintainBillModel: [],
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "upkeepContractNumber",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return `<a>${row.upkeepContractNumber}</a>`;
          },
          method: (row) => {
            enterEditPage(row);
          },
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
          formatter: (row) => {
            return proxy.$defined.btnPermission("添加维保计划")
              ? `<a>${row.number}</a>`
              : `<span>${row.number}</span>`;
          },
          method: (row) => {
            if (proxy.$defined.btnPermission("添加维保计划")) {
              handleDialog("dialogFormVisible", true);
              state.editData = JSON.parse(JSON.stringify(row));
              state.formColumns.map((item) => {
                item.isUpdate = true;
                item.value = row[item.prop] + "";
              });
            }
          },
        },
        {
          prop: "maintenanceMan",
          label: "maintenancePerson",
          align: "center",
          search: true,
          type: "select",
          data: [],
          props: { label: "name", value: "name" },
        },
        {
          prop: "planTime",
          label: "startTime",
          align: "center",
        },
        {
          prop: "planEndTime",
          label: "endDate",
          align: "center",
        },
        {
          prop: "planType",
          label: "orderType",
          type: "select",
          search: true,
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "plan_type",
          formatter: (row) => {
            let current = state.maintainBillModel.filter((item) => {
              return item.value == row.planType;
            });
            return current.length === 0 ? "-" : t("i18n." + current[0].code);
          },
        },
        {
          prop: "monthNo",
          label: "theNTimeOfMaintenance",
          align: "center",
        },
        {
          prop: "remainDays",
          label: "remainingDays",
          align: "center",
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          formatter: (row) => {
            if (row.status == 10) {
              return t("i18n.notStarted");
            } else if (row.status == 20) {
              return t("i18n.started");
            } else {
              return t("i18n.ACCOMPLISH");
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      editData: {},
      formColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "maintenanceMan",
          label: "maintenancePerson",
          type: "select",
          props: { label: "name", value: null },
          data: [],
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "planType",
          label: "orderType",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "plan_type",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "planTime",
          label: "startTime",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "planEndTime",
          label: "endDate",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
    });

    const init = async (params) => {
      state.loading = true;
      params.elevatorNumber = params.number || "";
      params.contractNumber = params.upkeepContractNumber || "";
      const { data } = await proxy.$api.system.getRemindersPlan(params);
      data.pages.records.map((item, index) => {
        item.remainDays = data.remainDays[index];
      });
      state.tableData = data.pages.records;
      state.total = data.pages.total;
      state.loading = false;
    };

    const getMaintainBillModel = () => {
      proxy.$api.common.getLineageByCode("plan_type").then((res) => {
        state.maintainBillModel = res.data.lineages;
      });
    };

    const initData = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.columns.map((item) => {
        if (item.prop === "maintenanceMan") {
          item.data = data;
        }
      });
      getMaintainBillModel();
    };
    initData();

    const enterEditPage = (row) => {
      let page = row.upkeepType == 10 ? "Viewupdate" : "Viewcou";
      router.push({
        path: "/upkeepContract/upkeepContract" + page,
        query: { id: row.upkeepContractId },
      });
    };

    const setData = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.formColumns.map((item) => {
        if (item.prop === "maintenanceMan") {
          item.data = data;
        }
      });
    };
    setData();

    const handleDialog = (name, flag) => {
      state[name] = flag;
    };

    const onSubmit = async (data) => {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = proxy.$defined.format(data[key]);
        }
      }
      let row = JSON.parse(JSON.stringify(data));
      if (row.maintenanceMan instanceof Object) {
        row.maintenanceManId = row.maintenanceMan.id;
        row.maintenanceMan = row.maintenanceMan.name;
      }
      row.elevatorId = state.editData.elevatorId;
      await proxy.$api.maintenance.addMaintainPlan(row);
      state.dialogFormVisible = false;
      init(state.formInline);
    };

    return {
      ...toRefs(state),
      enterEditPage,
      init,
      handleDialog,
      onSubmit,
    };
  },
};
</script>
